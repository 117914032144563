import { App } from 'vue'
import MainToggler from './MainToggler.vue'
import Sidebar from './Sidebar.vue'
import MainHeader from './MainHeader.vue'
import SubHeader from './SubHeader.vue'
import MainFooter from './MainFooter.vue'
import UserBar from './UserBar.vue'
import NotifyBar from './NotifyBar.vue'
import FiltersBar from './FiltersBar.vue'
import Checker from './Checker.vue'
import SimpleOutput from './SimpleOutput.vue'
import ActionMenu from './ActionMenu.vue'

const register = (app: App<Element>): void => {
  app
  .component('SimpleOutput', SimpleOutput)
  .component('FiltersBar', FiltersBar)
  .component('ActionMenu', ActionMenu)
  .component('Checker', Checker)
  .component('MainToggler', MainToggler)
  .component('Sidebar', Sidebar)
  .component('MainHeader', MainHeader)
  .component('SubHeader', SubHeader)
  .component('MainFooter', MainFooter)
  .component('UserBar', UserBar)
  .component('NotifyBar', NotifyBar)
}

export default {
  register,
}
