import { createApp, App } from "vue"
import { createPinia } from "pinia"
import { createRouter, createWebHistory } from "vue-router"
import createAppRouter from "@router"
import i18n from "./i18n"

import Components from "@/components"
import Modules from "@/modules"

type CreateApplication = {
  createApp: typeof createApp
  createRouter: typeof createRouter
  createWebHistory: typeof createWebHistory
  createPinia: typeof createPinia
}

export function createApplication({
  createApp,
  createRouter: createRouterInstance,
  createWebHistory,
  createPinia,
}: CreateApplication): App {
  const app = createApp({})

  app.config.compilerOptions.delimiters = ['[[', ']]']

  const pinia = createPinia()
  
  const router = createAppRouter({
    app,
    createInstance: createRouterInstance,
    createWebHistory,
    pinia,
  })

  app
    .use(pinia)
    .use(i18n)
    .use(router)
    .use(Modules)
    .use(Components)
    .mount('#app')

  return app
}
