import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withCtx as _withCtx, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "qin-form qin-form--modal" }
const _hoisted_2 = { class: "qin-form__title" }
const _hoisted_3 = {
  key: 0,
  class: "ds-caption ds-caption--bold"
}
const _hoisted_4 = { class: "qin-form__wrapper" }
const _hoisted_5 = { class: "comment-block" }
const _hoisted_6 = {
  class: "g-row g-row--appearance_spaced g-row--space_lg g-row--justify_center g-row--align_center wrapper custom-scrollbar",
  ref: "scrollContainer"
}
const _hoisted_7 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_8 = { class: "qin-comment__avatar" }
const _hoisted_9 = { class: "ds-avatar ds-avatar--appearance_rounded ds-avatar--size_custom-2xs" }
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  key: 1,
  class: "ds-avatar__label"
}
const _hoisted_12 = { class: "qin-comment__text" }
const _hoisted_13 = { class: "qin-comment__title" }
const _hoisted_14 = { class: "qin-comment__message-text" }
const _hoisted_15 = { class: "qin-comment__date" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "error-label" }
const _hoisted_18 = { class: "g-row g-row--appearance_spaced g-row--space_sm g-row--space_lg-xl g-row--justify_center g-row--align_center" }
const _hoisted_19 = {
  key: 0,
  class: "g-cell g-cols g-cols--12"
}
const _hoisted_20 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_21 = { class: "qin-button__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader = _resolveComponent("loader")!
  const _component_i_check_double = _resolveComponent("i-check-double")!
  const _component_i_trash = _resolveComponent("i-trash")!
  const _component_VeeError = _resolveComponent("VeeError")!
  const _component_d_control_input = _resolveComponent("d-control-input")!
  const _component_VeeForm = _resolveComponent("VeeForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.load)
      ? (_openBlock(), _createBlock(_component_loader, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        ($setup.props.info)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString($setup.$t('Comment History for')) + " " + _toDisplayString('#') + _toDisplayString($setup.props.info.id), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.commentsList, (comment, index) => {
              return (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", {
                  class: _normalizeClass(["qin-comment", {'owner' : comment.isOwner}])
                }, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      (comment.avatar && comment.avatar.length)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            class: "ds-avatar__image",
                            src: comment.avatar
                          }, null, 8, _hoisted_10))
                        : (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(comment.createdBy? comment.createdBy[0] : '?'), 1))
                    ])
                  ]),
                  _createElementVNode("div", {
                    class: _normalizeClass(["qin-comment__message", {'checked' : comment.status == 'read'}])
                  }, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("p", _hoisted_13, _toDisplayString(comment.createdBy), 1),
                      _createElementVNode("p", _hoisted_14, _toDisplayString(comment.text), 1)
                    ]),
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("span", null, _toDisplayString(comment.createdAt), 1),
                      (comment.isOwner)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createElementVNode("div", {
                              class: _normalizeClass(["qin-comment__check-icon", {'checked' : comment.status == 'read'}])
                            }, [
                              _createVNode(_component_i_check_double)
                            ], 2),
                            (comment.canDelete)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 0,
                                  class: "qin-comment__delete-button pointer",
                                  onClick: _withModifiers(($event: any) => ($setup.removeItem(comment.id)), ["prevent"])
                                }, [
                                  _createVNode(_component_i_trash)
                                ], 8, _hoisted_16))
                              : _createCommentVNode("", true)
                          ], 64))
                        : _createCommentVNode("", true)
                    ])
                  ], 2)
                ], 2)
              ]))
            }), 256))
          ], 512)
        ]),
        _createVNode(_component_VeeForm, { onSubmit: $setup.request }, {
          default: _withCtx(({ errors }) => [
            _createVNode(_component_VeeError, { name: "nonFieldErrors" }, {
              default: _withCtx(({ message }) => [
                _createElementVNode("div", _hoisted_17, _toDisplayString(message), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_18, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.fields, (field) => {
                return (_openBlock(), _createElementBlock(_Fragment, {
                  key: field.name
                }, [
                  (field.condition ? field.condition() : true)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                        _createVNode(_component_d_control_input, _mergeProps({
                          component: field.tag,
                          "input-label": field.label,
                          name: field.name,
                          rules: field.rules,
                          ref_for: true,
                          ref: field.name,
                          type: field.type
                        }, field, {
                          modelValue: $setup.form[field.name],
                          "onUpdate:modelValue": ($event: any) => (($setup.form[field.name]) = $event),
                          placeholder: field.placeholder || ''
                        }), null, 16, ["component", "input-label", "name", "rules", "type", "modelValue", "onUpdate:modelValue", "placeholder"])
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              }), 128)),
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("button", {
                  class: _normalizeClass(["qin-button qin-button--main qin-button--accent qin-button--full_width qin-button--offset_top-md", {'disabled' : !$setup.form.text}]),
                  type: 'submit'
                }, [
                  _createElementVNode("span", _hoisted_21, _toDisplayString($setup.$t('Send')), 1)
                ], 2)
              ])
            ])
          ]),
          _: 1
        })
      ])
    ])
  ], 64))
}