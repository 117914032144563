import { createApp, App } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { createPinia } from 'pinia'
import { createApplication } from '@/app'

import './styles/index.sass'


export const app = createApplication({
  createApp, createRouter, createWebHistory, createPinia,
})

window.app = app

declare global {
  interface Window {
    // USER_PERMISSIONS: any
    // ALL_PERMISSIONS: any
    app: App
  }
}

