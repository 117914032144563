/*
  eslint-disable
    max-classes-per-file,
    prefer-spread,
    prefer-rest-params,
    class-methods-use-this,
    prefer-object-spread
*/

import { Permission } from '@aspectus/permissions';

export function waitForUser(state, getter, timeout = 5000, tick = 10) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      let check = null;
      const rejector = () => (clearTimeout(check) || reject());
      const ticker = () => {
        const userState = getter(state);
        if (!userState) reject();
        
        const user = userState && userState.user
        if (user && user.determined) {
          if (user.isAuthenticated()) {
            resolve(user);
          } else {
            reject();
          }
          return;
        }
        check = setTimeout(ticker, tick);
      };
      setTimeout(rejector, timeout);
      ticker();
    }, 200)
  });
}

export class UserCheckerPermissionBase extends Permission {
  constructor({ getter, timeout = 5000, tick = 5 } = {}) {
    super();

    this.getter = getter;
    this.timeout = timeout;
    this.tick = tick;
  }

  getStore(vm, { pinia } = {}) {
    return vm && vm.$pinia || pinia;
  }

  waitForUser(vm, args) {
    const store = this.getStore(vm, args[0] || {});
    return waitForUser(
      store, this.getter, this.timeout, this.tick
    );
  }
  
  checkUser() {
    return false;
  }

  onHasAccess(vm, ...args) {
    return this.waitForUser(vm, args).then(user => {
      if (!this.checkUser(user, vm, ...args)) {
        throw user;
      }
    }, e => {
      console.log(e);
    });
  }
}

export class UserCheckerPermission extends UserCheckerPermissionBase {
  constructor({ checker, ...rest }) {
    super(rest);
    this.checker = checker;
  }

  checkUser() {
    return this.checker.apply(null, arguments);
  }
}

export function checkAuth(checker, config = {}, Base = UserCheckerPermission) {
  return new Base(Object.assign({ checker }, config));
}
