import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "qin-auth-page" }
const _hoisted_2 = { class: "qin-auth-page__form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sub_header = _resolveComponent("sub-header")!
  const _component_auth_form = _resolveComponent("auth-form")!
  const _component_password_reset = _resolveComponent("password-reset")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_sub_header),
    _createElementVNode("div", _hoisted_2, [
      (!$setup.isResetForm)
        ? (_openBlock(), _createBlock(_component_auth_form, {
            key: 0,
            onChangeForm: _cache[0] || (_cache[0] = () => {$setup.isResetForm = true})
          }))
        : (_openBlock(), _createBlock(_component_password_reset, {
            key: 1,
            onChangeForm: _cache[1] || (_cache[1] = () => {$setup.isResetForm = false})
          }))
    ])
  ]))
}