import { App } from 'vue'
import Tabs from './tabs/Tabs.vue'
import Tab from './tabs/Tab.vue'
import UiLimitPagination from './pagination/UiLimitPagination.vue'
import UiModalsContainer from './modals/Container.vue'
import UiMessageModal from './modals/UiMessageModal.vue'
import UiConfirmModal from './modals/UiConfirmModal.vue'
import UiLogoutConfirmModal from './modals/UiLogoutConfirmModal.vue'
import ModalTrigger from './ModalTrigger.vue'
import SidebarTrigger from './SidebarTrigger.vue'
import UiClickOutside from './UiClickOutside.vue'
import Table from './Table.vue'
import Loader from './loader.vue'
import UiAccordion from './UiAccordion.vue'
import PasswordChengeModal from './modals/PasswordChengeModal.vue'
import EmailChengeModal from './modals/EmailChengeModal.vue'
import EmailConfirmModal from './modals/EmailConfirmModal.vue'
import ClientCreateModal from './modals/ClientCreateModal.vue'
import PasswordGenerateModal from './modals/PasswordGenerateModal.vue'
import LPCreateModal from './modals/LPCreateModal.vue'
import CommentsModal from './modals/CommentsModal.vue'
import TransactionEditModal from './modals/TransactionEditModal.vue'
import TransactionInInvoiceEditModal from './modals/TransactionInInvoiceEditModal.vue'
// import Sidebar from './Sidebar.vue'

const register = (app: App<Element>): void => {
  app
  .component('Tab', Tab)
  .component('Tabs', Tabs)
  .component('UiTable', Table)
  .component('UiLimitPagination', UiLimitPagination)
  .component('ModalTrigger', ModalTrigger)
  .component('SidebarTrigger', SidebarTrigger)
  .component('UiClickOutside', UiClickOutside)
  .component('UiModalsContainer', UiModalsContainer)
  .component('UiMessageModal', UiMessageModal)
  .component('UiConfirmModal', UiConfirmModal)
  .component('UiLogoutConfirmModal', UiLogoutConfirmModal)
  .component('Loader', Loader)
  .component('UiAccordion', UiAccordion)
  .component('PasswordChengeModal', PasswordChengeModal)
  .component('EmailChengeModal', EmailChengeModal)
  .component('EmailConfirmModal', EmailConfirmModal)
  .component('ClientCreateModal', ClientCreateModal)
  .component('PasswordGenerateModal', PasswordGenerateModal)
  .component('LPCreateModal', LPCreateModal)
  .component('CommentsModal', CommentsModal)
  .component('TransactionEditModal', TransactionEditModal)
  .component('TransactionInInvoiceEditModal', TransactionInInvoiceEditModal)
  // .component('Sidebar', Sidebar)
}

export default {
  register,
}
