import {
  createSender,
} from '@/resource/resource'

import {
  prefixAPI,
  API_BASE_URL,
} from '@/resource/api'

const AUTH_MODEL = 'auth'
const PASSWORD_MODEL = 'auth/password'

// const AUTHENTICATE_LINK = prefixAPI('/login/', MODEL)

const LOGIN_LINK = prefixAPI('/login/', AUTH_MODEL)
const LOGOUT_LINK = prefixAPI('/logout/', AUTH_MODEL)
const PASSWORD_SET_LINK = prefixAPI('/set/', PASSWORD_MODEL)
const PASSWORD_CHANGE_LINK = prefixAPI('/change/', PASSWORD_MODEL)
const PASSWORD_RECOVER_LINK = prefixAPI('/recover/', PASSWORD_MODEL)

export const loginResource = createSender(LOGIN_LINK)
export const logoutResource = createSender(LOGOUT_LINK)

export const passwordChange = createSender(PASSWORD_CHANGE_LINK)
export const passwordSet = createSender(PASSWORD_SET_LINK)
export const passwordRecover = createSender(PASSWORD_RECOVER_LINK)
