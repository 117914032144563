import type { App } from 'vue'
import {
  Router,
  RouteRecordRaw,
  createWebHistory,
  createRouter as typeCreateRouter,
  RouteLocation,
  RouteLocationNormalizedLoaded,
} from 'vue-router'
import { Pinia } from 'pinia'
import createInitialRoutes from '@/router/routes'
import { accessGuard } from '@aspectus/vue-router-permissions';

export type Options = {
  app: App<Element>,
  createInstance: typeof typeCreateRouter,
  createWebHistory: typeof createWebHistory,
  pinia: Pinia,
}

export function createRoutes(options: Options): typeof RouteRecordRaw[] {
  return [
    ...createInitialRoutes(options),
  ];
}
type _ScrollPositionNormalized = {
  behavior?: ScrollOptions['behavior']
  left: number
  top: number
}
export function scrollBehavior(
  to: typeof RouteLocation,
  from: typeof RouteLocationNormalizedLoaded,
  savedPosition: null | _ScrollPositionNormalized
): false | void | { top: number, left: number } | { el: string | Element } {
  if (savedPosition) {
    return savedPosition;
  }
  return {
    top: window.pageXOffset,
    left: window.pageYOffset,
  };
}

export default function createAppRouter(options: Options): typeof Router {
  const { createInstance, createWebHistory } = options;
  const router = createInstance({
    history: createWebHistory('/'),
    scrollBehavior,
    routes: createRoutes(options),
  });

  router.beforeEach(accessGuard(
    options
  ));

  return router;
}
