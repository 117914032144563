import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
import { allPermissions } from '@access/index'
import { accessPermission } from '@access/permissions'

const {
  STAFF: {
    LP: {
      VIEW_LIST,
    },
  },
} = allPermissions

const lpsList = () => import('./views/List.vue')

export const LPS_LIST_PAGE_NAME = 'cabinet:lps-list'

// URLs
export const LPS_LIST_PAGE_URL = ''

export function createRoutes(): typeof RouteRecordRaw[] {
  return [
    route(LPS_LIST_PAGE_URL, lpsList, LPS_LIST_PAGE_NAME, {
      meta: {
        breadcrumb: 'lps',
        ...accessPermission(VIEW_LIST)
      }
    }),
  ]
}
