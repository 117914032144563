import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
import { allPermissions } from '@access/index'
import { accessPermission } from '@access/permissions'

const {
  STAFF: {
    CLIENT: {
      CREATE,
      VIEW_LIST,
      EDIT,
    },
  },
} = allPermissions

const clientList = () => import('./views/List.vue')

export const CLIENTS_LIST_PAGE_NAME = 'cabinet:clients-list'

// URLs
export const CLIENTS_LIST_PAGE_URL = ''

export function createRoutes(): typeof RouteRecordRaw[] {
  return [
    route(CLIENTS_LIST_PAGE_URL, clientList, CLIENTS_LIST_PAGE_NAME, {
      meta: {
        breadcrumb: 'clients',
        ...accessPermission(VIEW_LIST),
      }
    }),
  ]
}
