import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  key: 0,
  class: "qin-action-menu-wrapper__content"
}
const _hoisted_3 = { class: "qin-action-menu-wrapper__icon" }
const _hoisted_4 = { class: "qin-action-menu-wrapper__text" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "qin-action-menu-wrapper__icon" }
const _hoisted_7 = { class: "qin-action-menu-wrapper__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_vertical_dots = _resolveComponent("i-vertical-dots")!
  const _component_ui_click_outside = _resolveComponent("ui-click-outside")!
  const _component_sidebar_trigger = _resolveComponent("sidebar-trigger")!

  return (_openBlock(), _createBlock(_component_sidebar_trigger, { "input-ref": _ctx.$emit }, {
    default: _withCtx(({ sidebarIsOpen, sidebarToggle, sidebarClose }) => [
      _createVNode(_component_ui_click_outside, { do: sidebarClose }, {
        default: _withCtx(({ ref }) => [
          _createElementVNode("div", {
            class: _normalizeClass(["qin-action-menu-wrapper", {'is-active': sidebarIsOpen}]),
            ref: ref
          }, [
            _createElementVNode("div", null, [
              _createElementVNode("div", {
                class: "qin-action-menu",
                onClick: _withModifiers(sidebarToggle, ["prevent"])
              }, [
                _createVNode(_component_i_vertical_dots, { class: "pointer" })
              ], 8, _hoisted_1),
              sidebarIsOpen
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.menuData, (el) => {
                      return (_openBlock(), _createElementBlock(_Fragment, null, [
                        (el.id == 'download' && !el.hidden)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: _normalizeClass(["qin-action-menu-wrapper__menu-element ds-link", {'disabled' : el.active && !el.active()}]),
                              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.fileDownload($setup.props?.element?.reportId, $setup.props?.element?.reportOriginalId)))
                            }, [
                              _createElementVNode("div", _hoisted_3, [
                                (_openBlock(), _createBlock(_resolveDynamicComponent(el.icon)))
                              ]),
                              _createElementVNode("div", _hoisted_4, _toDisplayString(el.title), 1)
                            ], 2))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              (!el.hidden)
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: _normalizeClass(["qin-action-menu-wrapper__menu-element", {'disabled' : el.active && !el.active() }]),
                                    onClick:  () => { $setup.emit("action", el.id), sidebarClose() }
                                  }, [
                                    _createElementVNode("div", _hoisted_6, [
                                      (_openBlock(), _createBlock(_resolveDynamicComponent(el.icon)))
                                    ]),
                                    _createElementVNode("div", _hoisted_7, _toDisplayString(el.title), 1)
                                  ], 10, _hoisted_5))
                                : _createCommentVNode("", true)
                            ], 64))
                      ], 64))
                    }), 256))
                  ]))
                : _createCommentVNode("", true)
            ])
          ], 2)
        ]),
        _: 2
      }, 1032, ["do"])
    ]),
    _: 1
  }, 8, ["input-ref"]))
}