import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
import { allPermissions } from '@access/index'
import { accessPermission } from '@access/permissions'

const {
  STAFF: {
    LP: {
      CREATE,
      VIEW_LIST,
      EDIT,
    },
  },
} = allPermissions

const Notifications = () => import('./List.vue')

export const NOTIFICATIONS_PAGE_NAME = 'cabinet:notifications'

export const NOTIFICATIONS_PAGE_URL = ''


export function createRoutes(): typeof RouteRecordRaw[] {
  return [
    route(NOTIFICATIONS_PAGE_URL, Notifications, NOTIFICATIONS_PAGE_NAME, {
      meta: {
        breadcrumb: 'notifications',
      },
    }),
  ]
}
