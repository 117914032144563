import { App } from 'vue'
import IEye from './i-eye.vue'
import IEyeOff from './i-eye-off.vue'
import ITableActions from './i-table-actions.vue'
import ISideClients from './i-side-clients.vue'
import ISideLps from './i-side-lps.vue'
import ISideFaq from './i-side-faq.vue'
import ISideLogout from './i-side-logout.vue'
import ISideNotify from './i-side-notify.vue'
import ISideProfile from './i-side-profile.vue'
import ISideSupport from './i-side-support.vue'
import ISideTransactions from './i-side-transaction.vue'
import IEdit from './i-edit.vue'
import IComment from './i-comment.vue'
import ICommentEmpty from './i-comment-empty.vue'
import IQ from './i-q.vue'
import IReport from './i-report.vue'
import IUndo from './i-undo.vue'
import IInfo from './i-info.vue'
import ISettings from './i-settings.vue'
import INoImage from './i-no-image.vue'
import IArrowRight from './i-arrow-right.vue'
import ICheckCircle from './i-check-circle.vue'
import IAddSimple from './i-add-simple.vue'
import IClose from './i-close.vue'
import IArrowDown from './i-arrow-down.vue'
import ILogout from './i-logout.vue'
import ISearch from './i-search.vue'
import INotify from './i-notify.vue'
import IChevronLeft from './i-chevron-left.vue'
import IChevronRight from './i-chevron-right.vue'
import IChevronUp from './i-chevron-up.vue'
import IChevronDown from './i-chevron-down.vue'
import IBannerInfo from './i-banner-info.vue'
import IDate from './i-date.vue'
import IVerticalDots from './i-vertical-dots.vue'
import IPlus from './i-plus.vue'
import IMinus from './i-minus.vue'
import ITrash from './i-trash.vue'
import ICheckDouble from './i-check-double.vue'
import ISideReports from './i-side-reports.vue'
import IOpen from './i-open.vue'
import IDownload from './i-download.vue'

const register = (app: App<Element>): void => {
  app
  .component('IOpen', IOpen)
  .component('IDownload', IDownload)
  .component('ISideReports', ISideReports)
  .component('ICheckDouble', ICheckDouble)
  .component('ITrash', ITrash)
  .component('IMinus', IMinus)
  .component('IPlus', IPlus)
  .component('IVerticalDots', IVerticalDots)
  .component('IChevronUp', IChevronUp)
  .component('IChevronDown', IChevronDown)
  .component('IDate', IDate)
  .component('IBannerInfo', IBannerInfo)
  .component('ISideLps', ISideLps)
  .component('IChevronLeft', IChevronLeft)
  .component('IChevronRight', IChevronRight)
  .component('INotify', INotify)
  .component('ISearch', ISearch)
  .component('ILogout', ILogout)
  .component('IArrowDown', IArrowDown)
  .component('IClose', IClose)
  .component('IAddSimple', IAddSimple)
  .component('IEye', IEye)
  .component('IEyeOff', IEyeOff)
  .component('ITableActions', ITableActions)
  .component('ISideClients', ISideClients)
  .component('ISideFaq', ISideFaq)
  .component('ISideLogout', ISideLogout)
  .component('ISideNotify', ISideNotify)
  .component('ISideProfile', ISideProfile)
  .component('ISideSupport', ISideSupport)
  .component('ISideTransactions', ISideTransactions)
  .component('IEdit', IEdit)
  .component('IComment', IComment)
  .component('ICommentEmpty', ICommentEmpty)
  .component('IQ', IQ)
  .component('IReport', IReport)
  .component('IUndo', IUndo)
  .component('IInfo', IInfo)
  .component('ISettings', ISettings)
  .component('INoImage', INoImage)
  .component('IArrowRight', IArrowRight)
  .component('ICheckCircle', ICheckCircle)
}

export default {
  register,
}
