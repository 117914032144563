import {
  createSender,
  createOptionsGetter,
  createReceiver,
  createUpdater,
} from '@/resource/resource'

import {
  prefixAPI,
} from '@/resource/api'

const MODEL = 'lp'

const LP_CREATE_LINK = prefixAPI('/create/', MODEL)
const LP_UPDATE_LINK = prefixAPI('/update/{id}/', MODEL)
const LP_PASSWORD_GENERATE_LINK = prefixAPI('/password/generate/', MODEL)
const LP_PASSWORD_SET_LINK = prefixAPI('/password/set/', MODEL)
const LPS_LIST_LINK = prefixAPI('/list/{?limit,offset,user_active,search}', MODEL)

export const lpCreateResource = createSender(LP_CREATE_LINK)
export const lpUpdateResource = createUpdater(LP_UPDATE_LINK)
export const lpPasswordGenerateResource = createReceiver(LP_PASSWORD_GENERATE_LINK)
export const lpPasswordSetResource = createSender(LP_PASSWORD_SET_LINK)
export const lpsListResource = createReceiver(LPS_LIST_LINK)

