import { App } from 'vue'
import AuthForm from './AuthForm.vue'
import PasswordReset from './PasswordReset.vue'
import PasswordSet from './PasswordSet.vue'

const register = (app: App<Element>): void => {
  app
  .component('AuthForm', AuthForm)
  .component('PasswordReset', PasswordReset)
  .component('PasswordSet', PasswordSet)
}

export default {
  register,
}
