import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "qin-simple-output" }
const _hoisted_2 = {
  key: 0,
  class: "qin-simple-output__label"
}
const _hoisted_3 = {
  key: 1,
  class: "qin-simple-output__description"
}
const _hoisted_4 = { class: "qin-simple-output__element relative" }
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_A = _resolveComponent("A")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.props.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString($setup.props.label), 1)
        ]))
      : _createCommentVNode("", true),
    ($setup.props.description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("span", null, _toDisplayString($setup.props.description), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (!$setup.props.isLink)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString($setup.props.value), 1))
        : (_openBlock(), _createBlock(_component_A, {
            key: 1,
            class: "ds-link ds-link--hovered",
            href: $setup.linksHref[$setup.props.linkType]
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.props.value), 1)
            ]),
            _: 1
          }, 8, ["href"])),
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}