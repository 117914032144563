export const QIN_ACCESS_TOKEN_STORAGE_NAME = 'LS:ACCESS_TOKEN_STORAGE'
export const QIN_REFRESH_TOKEN_STORAGE_NAME = 'LS:REFRESH_TOKEN_STORAGE'
export const QIN_DEVICE_UUID_STORAGE_NAME = 'LS:DEVICE_UUID_STORAGE'

export const QIN_LANGUAGE_COOKIE_NAME = 'LS:CHOSEN_LANGUAGE'

export const QIN_CURRENT_PAYMENT_ID_NAME = 'LS:CURRENT_PAYMENT_ID'
export const QIN_CURRENT_PAYMENT_URL_NAME = 'LS:CURRENT_PAYMENT_URL'

export const QIN_USER_SAVED_NAME = 'LS:USER_SAVED_EMAIL'
export const QIN_IS_AUTH_STORAGE_NAME = 'LS:IS_AUTH_STORAGE_NAME'

export const QIN_WAREHOUSE_ID_STORAGE_NAME = 'LS:WAREHOUSE_ID'
export const QIN_WAREHOUSE_TYPE_STORAGE_NAME = 'LS:WAREHOUSE_TYPE'

