<template>
  <div>
    <up-uploader
    :url="currentUrl"
    label=''
    placeholder-src="/static/app/user.svg"
    :src="src"
    :uploadOptions="{ method: 'PATCH', initialHeaders: getHeaders(), field: field }"
    @result="onResult"
    @save="onSave"
    @error="onError"
    v-bind="$attrs"
    >
    <!-- Passing slots inside. -->
    <template v-for="(_, sn) in $slots" v-slot:[sn]="sd">
      <slot :name="sn" v-bind="sd" />
    </template>
    </up-uploader>
    <div v-if="sizeError" class="error-label error-label--size-error"> {{ sizeErrorMessage }} </div>
  </div>
</template>

<script lang="ts" setup>
import {
  defineProps,
  defineEmits,
  ref,
  computed,
  watch,
} from 'vue'

import {
  LS_ACCESS_TOKEN_STORAGE_NAME,
} from '@/const'

import useGlobal from '@/composables/useGlobal'

import Cookies from 'js-cookie';

// исправить на свои
const ACCESS_TOKEN_COOKIE = LS_ACCESS_TOKEN_STORAGE_NAME
const { $t } = useGlobal()

const sizeError = ref(false)
const sizeErrorMessage = $t('File must not exceed 5 MB')
const loaderDefaultLabel = $t('Добавить фото')

const props = defineProps({
  displayLabel: {
  },
  currentUrl: String,
  modelValue: {},
  field: {
    type: String,
    default: 'field'
  }
})

const emit = defineEmits(['update:modelValue'])
const preview = ref(null)
const currentUrl = ref('')
const src = computed(() => {
  const v = props.modelValue

  if (preview.value) return preview.value

  return (
    !v ? null : (
      typeof v === 'string'
      ? v
      : v.file
    )
  )
})

watch(() => props.currentUrl, () => {
  currentUrl.value = props.currentUrl
}, {immediate: true, deep: true})

function onResult(r) {
  preview.value = r.canvas.toDataURL()
  sizeError.value = false
  // If it was a @file handler.
  // toBase64(blob).then(base64 => {
  //   preview.value = base64
  // })
}

const getHeaders = () => {
  return [['X-Csrftoken', `${Cookies.get('csrftoken')}`]]
}

function onSave(xhr: XMLHttpRequest) {
  preview.value = null
  emit(
    'update:modelValue',
    !xhr ? null : JSON.parse(xhr.responseText).data.item.avatar
  )
}

function onError(e) {
  preview.value = null
  sizeError.value = true
  setTimeout(() => {
    sizeError.value = false
  }, 4000);

}

</script>
