import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "qin-form qin-form--modal" }
const _hoisted_2 = { class: "qin-form__title" }
const _hoisted_3 = { class: "ds-caption ds-caption--size_2xl ds-caption--bold" }
const _hoisted_4 = { class: "qin-form__wrapper" }
const _hoisted_5 = { class: "error-label" }
const _hoisted_6 = { class: "g-row g-row--appearance_spaced g-row--space_sm g-row--space_xl-xl g-row--justify_center g-row--align_center" }
const _hoisted_7 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_8 = { class: "qin-button__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader = _resolveComponent("loader")!
  const _component_VeeError = _resolveComponent("VeeError")!
  const _component_i_arrow_down = _resolveComponent("i-arrow-down")!
  const _component_d_control_input = _resolveComponent("d-control-input")!
  const _component_VeeForm = _resolveComponent("VeeForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.load)
      ? (_openBlock(), _createBlock(_component_loader, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, _toDisplayString($setup.$t('Edit Transaction')), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_VeeForm, { onSubmit: $setup.request }, {
          default: _withCtx(({ errors }) => [
            _createVNode(_component_VeeError, { name: "nonFieldErrors" }, {
              default: _withCtx(({ message }) => [
                _createElementVNode("div", _hoisted_5, _toDisplayString(message), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_6, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.fields, (field) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: field.name,
                  class: _normalizeClass(["g-cell g-cols relative", field.cols])
                }, [
                  _createVNode(_component_d_control_input, _mergeProps({
                    class: field.class && field.class($setup.form[field.name]),
                    component: field.tag,
                    "input-label": field.label,
                    name: field.name,
                    ref_for: true,
                    ref: field.name,
                    type: field.type
                  }, field, {
                    modelValue: $setup.form[field.name],
                    "onUpdate:modelValue": ($event: any) => (($setup.form[field.name]) = $event),
                    placeholder: field.placeholder || '',
                    onOpen: ($event: any) => ($setup.optionsGetter('transaction', field))
                  }), {
                    caret: _withCtx(({ toggle }) => [
                      _createVNode(_component_i_arrow_down, { class: "multiselect-caret" })
                    ]),
                    _: 2
                  }, 1040, ["class", "component", "input-label", "name", "type", "modelValue", "onUpdate:modelValue", "placeholder", "onOpen"])
                ], 2))
              }), 128)),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("button", {
                  class: _normalizeClass(["qin-button qin-button--main qin-button--accent qin-button--full_width qin-button--offset_top-md", {'disabled' : !_ctx.stateFormIsChanged && !$setup.formIsChanged}]),
                  type: 'submit'
                }, [
                  _createElementVNode("span", _hoisted_8, _toDisplayString($setup.$t('Save')), 1)
                ], 2)
              ])
            ])
          ]),
          _: 1
        })
      ])
    ])
  ], 64))
}