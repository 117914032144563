import { App } from 'vue'
import { accessMeta } from '@aspectus/vue-router-permissions'
import { route, prefixRoutes } from '@/router/utils'
import { RouteRecordRaw } from 'vue-router'
import { allPermissions } from '@access/index'
import { accessPermission, } from '@access/permissions'

const {
  STAFF: {
    FAQ_CATEGORY: { VIEW_LIST: FAQ_VIEW_LIST },
    LP: { VIEW_LIST: LPS_VIEW_LIST },
    TRANSACTION: { VIEW_LIST: TRANSACTIONS_VIEW_LIST },
    FEEDBACK: { CREATE: FEEDBACK_CREATE },
    CLIENT: { VIEW_LIST: CLIENTS_VIEW_LIST },
    REPORT: { VIEW_LIST: REPORTS_VIEW_LIST },
  },
} = allPermissions

import { createRoutes as createNotificationsRoutes } from './modules/notifications/router'
import { createRoutes as createFaqRoutes } from './modules/faq/router'
import { createRoutes as createSupportRoutes } from './modules/support/router'
import { createRoutes as createClientsRoutes } from './modules/clients/router'
import { createRoutes as createTransactionsRoutes } from './modules/transactions/router'
import { createRoutes as createLpsRoutes } from './modules/lps/router'
import { createRoutes as createProfileRoutes, PROFILE_PAGE_NAME } from './modules/profile/router'
import { createRoutes as createAccessDeniedRoutes } from './modules/403/router'
import { createRoutes as createPageNotFoundRoutes, PAGE_NOT_FOUND_PAGE_NAME } from './modules/404/router'
import { createRoutes as createReportsRoutes } from './modules/reports/router'
const textPage = () => import('./modules/text-page/text-page.vue')

const Cabinet = () => import('./views/Cabinet.vue')

export const BASE_PAGE_NAME = 'cabinet:page'
export const BASE_PAGE_URL = '/cabinet/'

export function createRoutes(): typeof RouteRecordRaw[]  {
  return [
    route(BASE_PAGE_URL, Cabinet, BASE_PAGE_NAME, {
      children: [
        // prefixRoutes('/login/', createAuthRoutes()),
        prefixRoutes('notifications/list/', createNotificationsRoutes()),
        prefixRoutes('faq/', createFaqRoutes(), { meta: { ...accessPermission(FAQ_VIEW_LIST) } }),
        prefixRoutes('transactions/list/', createTransactionsRoutes(), { meta: { ...accessPermission(TRANSACTIONS_VIEW_LIST) } }),
        prefixRoutes('support/', createSupportRoutes(), { meta: { ...accessPermission(FEEDBACK_CREATE) } }),
        prefixRoutes('profile/', createProfileRoutes()),
        prefixRoutes('clients/list/', createClientsRoutes(), { meta: { ...accessPermission(CLIENTS_VIEW_LIST) } }),
        prefixRoutes('lps/list/', createLpsRoutes(), { meta: { ...accessPermission(LPS_VIEW_LIST) } }),
        prefixRoutes('reports/list/', createReportsRoutes(), { meta: { ...accessPermission(REPORTS_VIEW_LIST) } }),
        prefixRoutes('not-found/', createPageNotFoundRoutes()),
        prefixRoutes('forbidden/', createAccessDeniedRoutes()),
        {
          path: '/cabinet/:pathMatch(.*)*',
          redirect: { name: PAGE_NOT_FOUND_PAGE_NAME }
        },
        route('info/:slug/', textPage, 'text-page', {}),
        ],
        redirect: { name: PROFILE_PAGE_NAME },
    }),
  ]
}