import type { Router } from 'vue-router'
import { defineStore } from '@/modules/pinia-hot'

import {
  countWithLpCreatedByImportResource,
} from '@/services/transactions.service'

import executeWithRefresh from '@/modules/refresher'

const id = 'transactions'

export const useTransactions = defineStore(id, {
  state: () => ({
    attentionsCount: '',
  }),
  getters: {
  },
  actions: {
    async getAttentionsCount() {
      const { data: { item } } = await countWithLpCreatedByImportResource.execute({})
      this.attentionsCount = item.amount
      console.log("🚀 ~ getUnread ~ data:", item)
      // this.attentionsCount = data[0]?.flags['73232']
    },
  },
})
