<template>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 2V5" stroke="#3A3A49" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16 2V5" stroke="#3A3A49" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.5 9.08997H20.5" stroke="#3A3A49" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="#3A3A49" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.6937 13.7H15.7027" stroke="#3A3A49" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.6937 16.7H15.7027" stroke="#3A3A49" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.9945 13.7H12.0035" stroke="#3A3A49" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.9945 16.7H12.0035" stroke="#3A3A49" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.29529 13.7H8.30427" stroke="#3A3A49" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.29529 16.7H8.30427" stroke="#3A3A49" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>