import {
  setCookie,
} from '@/utils/cookies'
import {
  QIN_ACCESS_TOKEN_STORAGE_NAME,
  QIN_REFRESH_TOKEN_STORAGE_NAME,
  QIN_IS_AUTH_STORAGE_NAME,
} from '@/const'

const currentURL = new URL(window.location.href)
const parts = currentURL.pathname.split("/")
const companyPart = parts[1]

export default async function useLogout() {
  return new Promise(resolve => {
    setCookie(QIN_ACCESS_TOKEN_STORAGE_NAME, '')
    setCookie(QIN_REFRESH_TOKEN_STORAGE_NAME, '')
    setCookie(QIN_IS_AUTH_STORAGE_NAME, '')
    resolve(true)
    window.location.href = `/${companyPart}/login`
  })
}