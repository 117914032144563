import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
import { allPermissions } from '@access/index'
import { accessPermission } from '@access/permissions'

const {
  STAFF: {
    FEEDBACK: {
      CREATE
    },
  },
} = allPermissions

const Support = () => import('./Support.vue')

export const SUPPORT_PAGE_NAME = 'cabinet:support'

export const SUPPORT_PAGE_URL = ''


export function createRoutes(): typeof RouteRecordRaw[] {
  return [
    route(SUPPORT_PAGE_URL, Support, SUPPORT_PAGE_NAME, {
      meta: {
        breadcrumb: 'support',
        ...accessPermission(CREATE)
      },
    }),
  ]
}
