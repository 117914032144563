import {
  createSender,
  createOptionsGetter,
  createReceiver,
  // createUpdater,
} from '@/resource/resource'

import {
  prefixAPI,
} from '@/resource/api'

const GET_OPTIONS_LINK = prefixAPI('options/{?object_title,option,warehouse}')

export const getOptionsResource = createReceiver(GET_OPTIONS_LINK)
