import {
  createSimpleGetter,
  createReceiver,
} from '@/resource/resource'

import {
  prefixAPI,
} from '@/resource/api'

const MODEL = 'report'

const REPORTS_LIST_LINK = prefixAPI('/list/{?limit,offset,search,lp,created_at_after,created_at_before,sort}', MODEL)
const REPORT_DOWNLOAD_LINK = prefixAPI('/download/{id}/', MODEL)

export const reportsListResource = createReceiver(REPORTS_LIST_LINK)
export const reportDownloadResource = createSimpleGetter(REPORT_DOWNLOAD_LINK)

