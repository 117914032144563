// import { prefixLanguage } from '../utils/urls';
import { RouteRecordRaw } from 'vue-router'
// import { accessMeta } from '@aspectus/vue-router-permissions';
// import { createRoutes as CommonRoutes } from '@/router/Common/routes'
// import { createRoutes as TraderRoutes } from '@/router/Trader/routes'
import { route, prefixRoutes } from '@/router/utils'
import { Options } from '@/router'
// import { toBeAuthenticated } from '../modules/users/permissions'
import { createRoutes as createAuthRoutes } from '@/components/pages/auth/router';
import { createRoutes as createCabinetRoutes } from '@/components/pages/cabinet/router';
// import {
//   getCookie,
// } from '@/utils/cookies'

import { AUTH_LOGIN_NAME } from '@/components/pages/auth/router';
import { BASE_PAGE_NAME } from '@/components/pages/cabinet/router';

// import {
//   AUTH_LOGIN,
//   // BASE_PAGE_NAME,
// } from '@/components/pages/auth/router'

// import {
//   QIN_ACCESS_TOKEN_STORAGE_NAME,
//   QIN_REFRESH_TOKEN_STORAGE_NAME,
// } from '@/const'
// const ViewComponent = () => import('@components/Profiles/Views/Profile.vue')
// const NoAllowed = () => import('@/components/Common/403.vue');
// const NotFound = () => import('@/components/Common/404.vue');

// const access = getCookie(QIN_ACCESS_TOKEN_STORAGE_NAME)
// const refresh = getCookie(QIN_REFRESH_TOKEN_STORAGE_NAME)

// const getRedirect = () => {
//   return AUTH_LOGIN_NAME
//   // return access || refresh ? BASE_PAGE_NAME :  AUTH_LOGIN_NAME
// }

export default function createInitialRoutes(options: Options): typeof RouteRecordRaw[] {
  return [
    // route(`/`, Route402, 'base', {
    //   children: [
    //     prefixRoutes('err/', [
    //       prefixRoutes('my/', [
    //         route('404/', NotFound, '404', {
    //           meta: {
    //             ...accessMeta(toBeAuthenticated, { path: '/login/', component: Route403 }, options),
    //           },
    //         }),
    //       ]),
    //     ]
    //     )
    //   ],
    //   meta: accessMeta(toBeAuthenticated, { path: '/login/' }, options),
    // }),
    prefixRoutes('/cabinet/', createCabinetRoutes()),
    prefixRoutes('', createAuthRoutes()),
    // { path: '/', redirect: { name: getRedirect() } },
    // prefixRoutes(prefixLanguage('/cabinet/'), createCabinetRoutes(options)),
    // route('/403/', NoAllowed, '403'),
    // route('/404/', NotFound, '404'),
    // { path: prefixLanguage('/'), redirect: { name: 'cabinet' } },
    // { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
    // useRoute(BASE_URL, ViewComponent, 'profile', {
    // redirect: { name: COMPANIES_ROUTE },
    // children: [
    //   ...CommonRoutes(options),
    //   ...TraderRoutes(options),
    // ],
    // }),
  ]
}