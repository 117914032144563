import {
  createSender,
  createOptionsGetter,
  createReceiver,
  createUpdater,
} from '@/resource/resource'

import {
  prefixAPI,
} from '@/resource/api'

const MODEL = 'client'

const CLIENT_CREATE_LINK = prefixAPI('/create/', MODEL)
const CLIENT_UPDATE_LINK = prefixAPI('/update/{id}/', MODEL)
const CLIENT_DELETE_LINK = prefixAPI('/delete/', MODEL)
const CLIENTS_LIST_LINK = prefixAPI('/list/{?limit,offset,id,search,approved}', MODEL)

export const clientCreateResource = createSender(CLIENT_CREATE_LINK)
export const clientUpdateResource = createUpdater(CLIENT_UPDATE_LINK)
export const clientDeleteResource = createSender(CLIENT_DELETE_LINK)
export const clientsListResource = createReceiver(CLIENTS_LIST_LINK)

