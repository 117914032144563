import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'
import { allPermissions } from '@access/index'
import { accessPermission } from '@access/permissions'

const {
  STAFF: {
    TRANSACTION: {
      VIEW_LIST
    },
  },
} = allPermissions

const transactionsList = () => import('./views/List.vue')

export const TRANSACTIONS_LIST_PAGE_NAME = 'cabinet:transactions-list'

// URLs
export const TRANSACTIONS_LIST_PAGE_URL = ''

export function createRoutes(): typeof RouteRecordRaw[] {
  return [
    route(TRANSACTIONS_LIST_PAGE_URL, transactionsList, TRANSACTIONS_LIST_PAGE_NAME, {
      meta: {
        breadcrumb: 'transactions',
        ...accessPermission(VIEW_LIST)
      }
    }),
  ]
}
