import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "attansion-info"
}
const _hoisted_2 = { class: "g-row g-row--appearance_spaced g-row--space_xl" }
const _hoisted_3 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_4 = { class: "ds-caption ds-caption--appearance_center" }
const _hoisted_5 = { class: "ds-caption ds-caption--semibold" }
const _hoisted_6 = { class: "g-cell g-cols g-cols--6-xs" }
const _hoisted_7 = { class: "qin-button__text" }
const _hoisted_8 = { class: "g-cell g-cols g-cols--6-xs" }
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "qin-button__text" }
const _hoisted_11 = { class: "qin-cabinet" }
const _hoisted_12 = { class: "qin-cabinet__sidebar" }
const _hoisted_13 = { class: "qin-cabinet__main" }
const _hoisted_14 = { class: "qin-cabinet__header" }
const _hoisted_15 = { class: "qin-cabinet__content relative" }
const _hoisted_16 = { class: "qin-cabinet__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sub_header = _resolveComponent("sub-header")!
  const _component_sidebar = _resolveComponent("sidebar")!
  const _component_main_header = _resolveComponent("main-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_main_footer = _resolveComponent("main-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.props?.isHijacked && !$setup.hideAttansion)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("p", _hoisted_4, [
                _createTextVNode(_toDisplayString($setup.$t('You are now working on behalf of')) + _toDisplayString(' '), 1),
                _createElementVNode("span", _hoisted_5, _toDisplayString($setup.profile.email), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("button", {
                class: "qin-button qin-button--main qin-button--accent-trans qin-button--full_width",
                onClick: _cache[0] || (_cache[0] = () => { $setup.hideAttansion = true })
              }, [
                _createElementVNode("span", _hoisted_7, _toDisplayString($setup.$t('Hide')), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("a", {
                class: "qin-button qin-button--main qin-button--accent qin-button--full_width",
                href: $setup.props?.hijackedReleaseLink
              }, [
                _createElementVNode("span", _hoisted_10, _toDisplayString($setup.$t('Release')), 1)
              ], 8, _hoisted_9)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_component_sub_header),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_sidebar)
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_main_header)
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_router_view)
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createVNode(_component_main_footer, {
            "text-pages": $setup.props.textPages
          }, null, 8, ["text-pages"])
        ])
      ])
    ])
  ], 64))
}