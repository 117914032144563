import {
  createSender,
  createOptionsGetter,
  createReceiver,
  createUpdater,
  createRemover,
} from '@/resource/resource'

import {
  prefixAPI,
} from '@/resource/api'

const MODEL = 'transaction'
const COMMENT_MODEL = 'transaction-comment'

const TRANSACTIONS_REPORT_LINK = prefixAPI('/report/', MODEL)
const TRANSACTIONS_UPDATE_LINK = prefixAPI('/update/{id}/', MODEL)
const TRANSACTIONS_CHANGESTATUS_LINK = prefixAPI('/change-status/', MODEL)
const TRANSACTIONS_TOGGLE_ACTIVATION_LINK = prefixAPI('/toggle-activation/', MODEL)
const TRANSACTIONS_UNDO_LINK = prefixAPI('/undo/', MODEL)
const TRANSACTIONS_EXPORT_LINK = prefixAPI('/export/{?search,status,ccy_product,instrument_type,search,timestamp,timestamp_after,timestamp_before,client__original_id,lp,sort,client,is_active,need_attention}', MODEL)
const TRANSACTIONS_LIST_LINK = prefixAPI('/list/{?limit,offset,status,ccy_product,instrument_type,search,timestamp,timestamp_after,timestamp_before,client__original_id,lp,sort,it,client,is_active,need_attention}', MODEL)
const TRANSACTIONS_REPORTS_LIST_LINK = prefixAPI('/list-reports-tab/{?limit,offset,search,in_report,in_invoice,lp,timestamp_after,timestamp_before}', MODEL)
const TRANSACTIONS_CREATED_BY_IMPORT_COUNTER_LINK = prefixAPI('/count-marked/', MODEL)


const TRANSACTIONS_COMMENT_LIST_LINK = prefixAPI('/list/{transaction}/', COMMENT_MODEL)
const TRANSACTIONS_COMMENT_CREATE_LINK = prefixAPI('/create/', COMMENT_MODEL)
const TRANSACTIONS_COMMENT_READ_LINK = prefixAPI('/read/{transaction}/', COMMENT_MODEL)
const TRANSACTIONS_COMMENT_DELETE_LINK = prefixAPI('/delete/{id}/', COMMENT_MODEL)

export const transactionsListResource = createReceiver(TRANSACTIONS_LIST_LINK)
export const transactionsExportResource = createReceiver(TRANSACTIONS_EXPORT_LINK)
export const transactionsReportsListResource = createReceiver(TRANSACTIONS_REPORTS_LIST_LINK)
export const transactionsReportResource = createSender(TRANSACTIONS_REPORT_LINK)
export const transactionsChangeStatusResource = createSender(TRANSACTIONS_CHANGESTATUS_LINK)
export const transactionsUndoResource = createSender(TRANSACTIONS_UNDO_LINK)
export const transactionsUpdateResource = createUpdater(TRANSACTIONS_UPDATE_LINK)
export const transactionsToggleActivationResource = createSender(TRANSACTIONS_TOGGLE_ACTIVATION_LINK)
export const countWithLpCreatedByImportResource = createReceiver(TRANSACTIONS_CREATED_BY_IMPORT_COUNTER_LINK)


export const transactionsCommentListResource = createReceiver(TRANSACTIONS_COMMENT_LIST_LINK)
export const transactionsCommentCreateResource = createSender(TRANSACTIONS_COMMENT_CREATE_LINK)
export const transactionsCommentReadResource = createSender(TRANSACTIONS_COMMENT_READ_LINK)
export const transactionsCommentDeleteResource = createRemover(TRANSACTIONS_COMMENT_DELETE_LINK)


