import {
  getCookie,
  setCookie,
} from '@/utils/cookies'
import {
  QIN_ACCESS_TOKEN_STORAGE_NAME,
  QIN_REFRESH_TOKEN_STORAGE_NAME,
} from '@/const'
import {
  headersMiddleware,
  overrideMiddleware,
} from '@aspectus/resource'
// import useDeviceAuthentication from '@/composables/useDeviceAuthentication'

export default async function useAuthToken(resource: any, access?:string|null, refresh?: string) {
  const HEADERS_MIDDLEWERE = headersMiddleware(overrideMiddleware({
    'Authorization': `Bearer ${access}`
  }))
  return resource.middleware(HEADERS_MIDDLEWERE, 600)
}