<script lang="ts">
import { defineComponent, SetupContext, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import type { Router } from 'vue-router'
import { useAuth } from '@/stores/auth'
import { useUserStore } from '@/stores/user'

import {
  getCookie,
  setCookie,
} from '@/utils/cookies'
import {
  ACCESS_TOKEN_COOKIE
} from '@/modules/refresher/const'

export default defineComponent({
  setup(props: unknown, { slots }: SetupContext) {
    onBeforeMount(async () => {
      // const authStore = useAuth()
      const userStore = useUserStore()
      // const { receive } = authStore
      const router = useRouter()
      await userStore.getUserData()
      // await receive(router)
    })
    return () => slots.default?.()
  },
})
</script>
