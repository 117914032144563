import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "qin-form qin-form--modal" }
const _hoisted_2 = { class: "qin-form__title" }
const _hoisted_3 = { class: "ds-caption ds-caption--size_2xl ds-caption--bold" }
const _hoisted_4 = { class: "qin-form__subtitle" }
const _hoisted_5 = {
  key: 0,
  class: "ds-caption ds-caption--size_xs ds-caption--color_semigrey"
}
const _hoisted_6 = { class: "ds-caption --bold" }
const _hoisted_7 = {
  key: 1,
  class: "ds-caption ds-caption--size_xs ds-caption--color_semigrey ds-caption--appearance_center"
}
const _hoisted_8 = { class: "ds-caption --bold" }
const _hoisted_9 = { class: "ds-caption --bold" }
const _hoisted_10 = { class: "qin-form__wrapper" }
const _hoisted_11 = { class: "error-label" }
const _hoisted_12 = { class: "g-row g-row--appearance_spaced g-row--space_sm g-row--space_xl-xl g-row--justify_center g-row--align_center" }
const _hoisted_13 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_14 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_15 = {
  class: "qin-button qin-button--main qin-button--accent qin-button--full_width qin-button--offset_top-md",
  type: 'submit'
}
const _hoisted_16 = { class: "qin-button__text" }
const _hoisted_17 = {
  key: 1,
  class: "g-cell g-cols g-cols--12"
}
const _hoisted_18 = { class: "check-icon-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader = _resolveComponent("loader")!
  const _component_VeeError = _resolveComponent("VeeError")!
  const _component_d_control_input = _resolveComponent("d-control-input")!
  const _component_i_check_circle = _resolveComponent("i-check-circle")!
  const _component_VeeForm = _resolveComponent("VeeForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.load)
      ? (_openBlock(), _createBlock(_component_loader, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, _toDisplayString($setup.$t('Generate Temporary Password')), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (!$setup.dataIsSended)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
              _createTextVNode(_toDisplayString($setup.$t("Email with temporary password is automatically sent to the user")), 1),
              _createElementVNode("span", _hoisted_6, _toDisplayString(' ') + " " + _toDisplayString($setup.form.name), 1)
            ]))
          : (_openBlock(), _createElementBlock("p", _hoisted_7, [
              _createTextVNode(_toDisplayString($setup.$t('The temporary password is')) + " ", 1),
              _createElementVNode("span", _hoisted_8, _toDisplayString($setup.form.password), 1),
              _createElementVNode("span", null, _toDisplayString('. The email with this password is sent to the user'), 1),
              _createElementVNode("span", _hoisted_9, _toDisplayString(' ') + " " + _toDisplayString($setup.form.name), 1)
            ]))
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_VeeForm, { onSubmit: $setup.request }, {
          default: _withCtx(({ errors }) => [
            _createVNode(_component_VeeError, { name: "nonFieldErrors" }, {
              default: _withCtx(({ message }) => [
                _createElementVNode("div", _hoisted_11, _toDisplayString(message), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_12, [
              (!$setup.dataIsSended)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("div", _hoisted_13, [
                      _createVNode(_component_d_control_input, {
                        rules: "",
                        readonly: true,
                        name: "password",
                        type: "text",
                        "input-label": $setup.$t("Password"),
                        modelValue: $setup.form.password,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.password) = $event))
                      }, null, 8, ["input-label", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("button", _hoisted_15, [
                        _createElementVNode("span", _hoisted_16, _toDisplayString($setup.$t('Send Code')), 1)
                      ])
                    ])
                  ], 64))
                : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _createVNode(_component_i_check_circle)
                    ])
                  ]))
            ])
          ]),
          _: 1
        })
      ])
    ])
  ], 64))
}