import { RouteRecordRaw } from 'vue-router';
import { route } from '@/router/utils'

import { allPermissions } from '@access/index'
import { accessPermission } from '@access/permissions'

const {
  STAFF: {
    FAQ_CATEGORY: {
      VIEW_LIST
    },
  },
} = allPermissions

const Faq = () => import('./List.vue')

export const FAQ_PAGE_NAME = 'cabinet:faq'

export const FAQ_PAGE_URL = ''


export function createRoutes(): typeof RouteRecordRaw[] {
  return [
    route(FAQ_PAGE_URL, Faq, FAQ_PAGE_NAME, {
      meta: {
        breadcrumb: 'faq',
        ...accessPermission(VIEW_LIST)
      },
    }),
  ]
}
