<template>
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_93_10226)">
<path d="M14.168 2.5C14.3802 2.2492 14.6427 2.04505 14.9388 1.90057C15.2349 1.75609 15.5581 1.67445 15.8877 1.66085C16.2173 1.64725 16.5462 1.70198 16.8533 1.82157C17.1604 1.94116 17.4391 2.12298 17.6716 2.35544C17.904 2.58789 18.0852 2.86589 18.2034 3.1717C18.3217 3.47752 18.3745 3.80445 18.3585 4.13165C18.3425 4.45885 18.258 4.77916 18.1104 5.07214C17.9628 5.36512 17.7554 5.62436 17.5013 5.83333L6.2513 17.0833L1.66797 18.3333L2.91797 13.75L14.168 2.5Z" stroke="#FF8300" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.5 4.16669L15.8333 7.50002" stroke="#FF8300" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_93_10226">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>
</template>