import { ref, Ref } from 'vue'
import { recaptcha_site_key } from '@access/permissions'

const recaptcha_site_key = window.recaptcha_site_key

interface UseMethods {
  key: string,
  form: Ref<any>,
  recaptcha: Ref<any|null>,
  captchaToken: Ref<string>,
  recaptchaVerified: (
    resp: string,
    request: any,
  ) => void,
  verify: () => Promise<void>,
  reset: () => void,
}

export default function (): UseMethods {
  const form: Ref<any> = ref(null)

  const reset = (): void => {
    captchaToken.value = ''
    recaptcha.value.reset()
  }

  const key = recaptcha_site_key
  const recaptcha: Ref<any|null> = ref(null)
  console.log("🚀 ~ recaptcha:", recaptcha)
  
  const captchaToken: Ref<string> = ref('')

  const recaptchaVerified = (resp: string, request: any): void => {
    captchaToken.value = resp
    request(form.value.getValues(), form.value)
    reset()
  }
  
  const verify = async () => {
    await recaptcha.value.execute()
  }

  return {
    key,
    form,
    recaptcha,
    captchaToken,
    recaptchaVerified,
    verify,
    reset,
  }
}
